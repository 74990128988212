import { IoCall, IoMail, IoMap } from 'react-icons/io5';
import {
  ScColumnsResponsive,
  ScFlexAlignCenter,
  ScHeader3,
  ScImage,
  ScMaxWidthContainer,
  ScMaxWidthContainerWide,
} from './../components/styled-components';

import Image from '../components/image';
import Layout from '../components/layout';
import React from 'react';
import SEO from '../components/seo';
import globals from '../utils/variables';
import styled from 'styled-components';

const ScBlackContainer = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const IndexPage = () => (
  <Layout>
    <SEO title="Balance & Effizienz im Alltag" keywords={globals.keywords} />
    <ScBlackContainer>
      <ScMaxWidthContainer>
        <ScColumnsResponsive>
          <div style={{ flex: '1', paddingLeft: '1rem' }}>
            <ScHeader3>Coaching</ScHeader3>
            <p>
              Viele werden die Bezeichnung Coaching vom Spitzensport kennen,
              hier werden Sportler durch persönliche, zielorientierte und
              leistungsorientierte Betreuung durch einen Coach zu
              Höchstleistungen trainiert.
            </p>
            <p>
              In der systemischen Arbeit versteht man unter Coaching die
              maßgeschneiderte Problemlösung im Spannungsdreieck zwischen Beruf,
              Organisation und Privatleben. Die Methoden zu dieser Problemlösung
              werden durch passende Fragen und hilfreiche Zusammenfassungen
              durch den Coach zum eigenständigen Lösungsauftrag für den Coachee
              (Klienten).
            </p>
            <p>
              Der Coach führt bzw. begleitet den Klienten und erarbeitet mit ihm
              die erwünschten neuen Verhaltensmuster, die der Klient als
              Zielsetzung selbst formuliert.
            </p>
            <p>
              Coaching setzt das Potential eines Menschen frei, seine eigenen
              Leistungen zu maximieren. Auf den Punkt gebracht, ist Coaching vor
              allem Arbeit auf der Beziehungsebene, bei welcher ein Klima des
              Vertrauens dazu führt, dass der Coachee lernt seine eigenen
              Talente und Ressourcen zu mobilisieren, um mit belastenden Lebens-
              und Berufssituationen besser klarzukommen.
            </p>
          </div>
          <ScImage
            style={{
              width: '30rem',
              height: '23rem',
              margin: '1rem',
            }}
            src="pictures/pexels-jakson-martins-3503098.jpg"
          />
        </ScColumnsResponsive>
      </ScMaxWidthContainer>
    </ScBlackContainer>
  </Layout>
);

export default IndexPage;
